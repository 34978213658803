import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import axios from "axios";
import { AsyncPaginate } from "react-select-async-paginate";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import Draggable from "react-draggable";
// import HomeMaxIcon from "@mui/icons-material/HomeMax";
import DataArrayIcon from "@mui/icons-material/DataArray";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { postData, env } from "../../../Utils/utils";

import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CustomizedDialogs({
  handleClickOpen,
  handleClose,
  open,
  fieldData,
  setLoading,
  pageData: pageStore,
  setOpen,
  modalInBackground,
  setModalInBackground,
  search,
  setSearch,
  fetchData,
  sortingOrder: { utill: { sorting_order = "none" } = {}, id: sortOrderId = 0 },
}) {
  const [fields, setfields] = useState([
    {
      sign: "",
      valueAssignment: "",
      standardDeviation: "",
      expandedUncertainity: "",
    },
  ]);
  console.log("sdjksdj", fieldData);
  const [total, settotal] = useState(0);
  async function loadOptions(search, loadedOptions, { page }) {
    let newarray = [];
    let has_more = true;
    let obj = {
      limit: 10,
      skip: (page - 1) * 10,

      name: search,
    };
    let url = "";
    if (search.length > 0) {
      url = `${env}/analytes/get?name=${search}&skip=0&limit=10&is_inventory=1`;
    } else {
      url = `${env}/analytes/get?skip=${obj.skip}&limit=${obj.limit}&is_inventory=1`;
    }
    if (loadedOptions.length === 0 || loadedOptions.length < total) {
      await axios
        .get(url)
        .then(async (response) => {
          let res = response.data;
          if (res.status === 200) {
            settotal(res.data.totalCount);
            for (var i = 0; i < res.data.analyte.length; i++) {
              newarray.push({
                label: res.data.analyte[i].primaryClass,
                value: res.data.analyte[i].primaryClass,
              });
            }
          } else {
            has_more = false;
          }
        })
        .catch((error) => {});
    }

    const responseJSON = {
      results: newarray,
      has_more: newarray.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }
  const [sortBy, setSortBy] = useState("none");
  useEffect(() => {
    setSortBy(sorting_order);
  }, [sorting_order]);
  const [data, setData] = useState({
    id: 0,
    primaryClass: "",
    analyte: [],
    pin: "",
    grade: "",
    uns: "",
    Class: "",
    rmrm: "",
    series: "",
    length: "",
    cost: "",
    freight: "",
    totalCost: "",
    poDate: "",
    po: "",
    heat: "",
    poSource: "",
    mill: "",
    received: "",
    final: "",
    replaced: "",
    symbol: "",
    digitsValueAssignment: "",
    digitsStandardDeviation: "",
    digitsExpandedUncertainity: "",
    dollarperinchminusthefreight: "",
    dollarperinch: "",
    dollarpermilimeterminusfreight: "",
    dollarpermilimeter: "",
    dollarper35milimeterpiece: "",
    dollarper19milimeterpiece: "",
    dollarper3milimeterpiece: "",
    dollarper12milimeterpiece: "",
    dollarper8milimeterpiece: "",
    dollarpergram: "",
    inventoryamountininches: "",
    inventoryinmilimeter: "",
    dollarvalueofinventory: "",
    date: "",
  });
  const {
    id = 0,
    primaryClass = "",
    analyte = [],
    pin = "",
    grade = "",
    uns = "",
    Class = "",
    rmrm = "",
    series = "",
    length = "",
    cost = "",
    freight = "",
    totalCost = "",
    poDate = "",
    po = "",
    heat = "",
    poSource = "",
    mill = "",
    received = "",
    final = "",
    replaced = "",
    symbol = "",
    digitsValueAssignment = "",
    digitsStandardDeviation = "",
    digitsExpandedUncertainity = "",
    dollarperinchminusthefreight = "",
    dollarperinch = "",
    dollarpermilimeterminusfreight = "",
    dollarpermilimeter = "",
    dollarper35milimeterpiece = "",
    dollarper19milimeterpiece = "",
    dollarper3milimeterpiece = "",
    dollarper12milimeterpiece = "",
    dollarper8milimeterpiece = "",
    dollarpergram = "",
    inventoryamountininches = "",
    inventoryinmilimeter = "",
    dollarvalueofinventory = "",
    date = "",
  } = data;
  React.useMemo(() => {
    setfields(fieldData);
  }, [fieldData]);
  React.useMemo(() => {
    setData(pageStore);
  }, [pageStore]);
  const [isError, setError] = useState(false),
    // eslint-disable-next-line
    [isInvalidDate, setInvalidDate] = useState(false),
    // eslint-disable-next-line
    // onPageChange = async (e, name) => {
    //   if (name === "file" && e?.target?.files?.[0]) {
    //     const image = e.target.files[0] || {};
    //     if (image.name && !image.name.match(/\.(pdf)$/)) {
    //       setPageStore({ ...pageStore, file: "" });
    //       return false;
    //     } else {
    //       setLoading(true);
    //       const { data: { url = "" } = {} } =
    //         (await uploadFile(
    //           e.target.files[0],
    //           "/common/upload/file",
    //           "certificates"
    //         )) || {};
    //       setPageStore({ ...pageStore, file: url });
    //       setLoading(false);
    //     }
    //   } else if (
    //     (name === "primaryClass" ||
    //       name === "analyte" ||
    //       name === "pin" ||
    //       name === "grade" ||
    //       name === "uns" ||
    //       name === "Class" ||
    //       name === "rmrm" ||
    //       name === "series" ||
    //       name === "length" ||
    //       name === "cost" ||
    //       name === "freight" ||
    //       name === "totalCost" ||
    //       name === "poDate" ||
    //       name === "po" ||
    //       name === "heat" ||
    //       name === "poSource" ||
    //       name === "mill" ||
    //       name === "received" ||
    //       name === "final" ||
    //       name === "replaced" ||
    //       name === "symbol" ||
    //       name === "sign" ||
    //       name === "digitsValueAssignment",
    //     name === "digitsStandardDeviation ",
    //     name === "digitsExpandedUncertainity",
    //     name === "valueAssignment" ||
    //       name === "standardDeviation" ||
    //       name === "expandedUncertainity" ||
    //       name === "dollarperinchminusthefreight" ||
    //       name === "dollarperinch" ||
    //       name === "dollarpermilimeterminusfreight" ||
    //       name === "dollarpermilimeter" ||
    //       name === "dollarper35milimeterpiece" ||
    //       name === "dollarper19milimeterpiece" ||
    //       name === "dollarper3milimeterpiece" ||
    //       name === "dollarper12milimeterpiece" ||
    //       name === "dollarper8milimeterpiece" ||
    //       name === "dollarpergram" ||
    //       name === "inventoryamountininches" ||
    //       name === "inventoryinmilimeter" ||
    //       name === "dollarvalueofinventory" ||
    //       name === "date")
    //   ) {
    //     setPageStore({ ...pageStore, [name]: e.target.value });
    //   } else if (name === "revision_date") {
    //     const date = new Date(e.target.value);
    //     if (date === "Invalid Date") {
    //       setInvalidDate(true);
    //     } else {
    //       setInvalidDate(false);
    //     }
    //     setPageStore({ ...pageStore, [name]: e.target.value });
    //   }
    // },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!analyte || !pin || !grade) {
        setError(true);
        return;
      }

      const obj = {
        analyte,
        primaryClass,
        pin,
        grade,
        uns,
        Class,
        rmrm,
        series,
        length,
        cost,
        freight,
        totalCost,
        poDate,
        po,
        heat,
        poSource,
        mill,
        received,
        final,
        replaced,
        symbol,
        digitsValueAssignment,
        digitsStandardDeviation,
        digitsExpandedUncertainity,
        dollarperinchminusthefreight,
        dollarperinch,
        dollarpermilimeterminusfreight,
        dollarpermilimeter,
        dollarper35milimeterpiece,
        dollarper19milimeterpiece,
        dollarper3milimeterpiece,
        dollarper12milimeterpiece,
        dollarper8milimeterpiece,
        dollarpergram,
        inventoryamountininches,
        inventoryinmilimeter,
        dollarvalueofinventory,
        date,
        active: 1,
      };
      obj.analyte = fields;
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/analytes/add");
      setTimeout(() => {
        handleClose();
      }, 1000);
      setLoading(false);
    };

  const removefields = (index) => {
    const temp = [...fields];

    const filter = temp.filter((item, i) => i !== index);

    setfields(filter);
  };
  const addfields = (index) => {
    setfields([
      ...fields,
      {
        valueAssignment: "",
        expandedUncertainity: "",
        standardDeviation: "",
        analyte: "",
        sign: "",
        digitsValueAssignment: "",
        digitsStandardDeviation: "",
        digitsExpandedUncertainity: "",
      },
    ]);
  };
  const handleChange = (e, name, index) => {
    const { value } = e.target;
    const list = [...fields];
    list[index][name] = value;
    setfields(list);
  };
  return (
    <div className="inventoryButtonGroup">
      {modalInBackground ? (
        <div className="btnWrap">
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
            className="maximizeButton btnPrimary"
          >
            Inventory
            <DataArrayIcon />
          </Button>
          <HighlightOffRoundedIcon
            onClick={() => {
              setModalInBackground(false);
            }}
            className="closeIcon"
          />
        </div>
      ) : null}

      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
        className="input__search"
      >
        <InputBase
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>

      <div style={{ marginLeft: "10px" }}>
        <FormControl sx={{ minWidth: "100px" }} size="small">
          <InputLabel>Sort By</InputLabel>
          <Select
            label="Sort By"
            value={sortBy}
            onChange={async (e) => {
              const obj = {
                name: "Inventory_Sorting",
                utill: { sorting_order: e.target.value },
              };
              if (sortOrderId) {
                obj.id = sortOrderId;
              }
              setLoading(true);
              await postData(obj, "/utills/save");
              setSortBy(e.target.value);
              setLoading(false);
              fetchData();
            }}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="primaryClass">Primary Class</MenuItem>
            <MenuItem value="grade">Grade</MenuItem>
            <MenuItem value="rmrm">Rmrm</MenuItem>
            <MenuItem value="pin">Pin</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={"btnPrimary"}
        style={{ marginLeft: "16px" }}
      >
        + New Item
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={open}
        className="add-inventory-dialog"
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle
          id="draggable-dialog-title"
          onClose={handleClose}
          className="inventory-dialog-head"
        >
          Inventory Item{" "}
          <div
            onClick={() => {
              setOpen(false);
              setModalInBackground(true);
            }}
            style={{ marginRight: "40px" }}
          >
            {" "}
            <DataArrayIcon />
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AsyncPaginate
                  value={{
                    label: primaryClass,
                    value: pin,
                  }}
                  placeholder={"Primary Class"}
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setData({
                      ...data,
                      primaryClass: e.value,
                    });
                  }}
                  additional={{
                    page: 1,
                  }}
                  className="analyteDrodownList"
                />
              </Grid>
              {fields && fields.length > 0
                ? fields.map((val, index) => {
                    return (
                      <>
                        {" "}
                        <div className="customGridContainer">
                          <Grid container spacing={0}>
                            <Grid item xs={8}>
                              <TextField
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                value={val.analyte}
                                onChange={(e) =>
                                  handleChange(e, "analyte", index)
                                }
                                label="Analyte"
                                variant="outlined"
                                error={isError && !val.analyte}
                                helperText={
                                  isError &&
                                  !val.analyte &&
                                  "Analyte is required"
                                }
                                style={{ maxWidth: "80px" }}
                              />
                              <TextField
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                value={val.valueAssignment}
                                onChange={(e) =>
                                  handleChange(e, "valueAssignment", index)
                                }
                                label="Value Assignment"
                                variant="outlined"
                                error={isError && !val.valueAssignment}
                                helperText={
                                  isError &&
                                  !val.valueAssignment &&
                                  "Value Assignment is required"
                                }
                              />
                              <TextField
                                value={val.digitsValueAssignment}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    "digitsValueAssignment",
                                    index
                                  )
                                }
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                label="Digit"
                                variant="outlined"
                                style={{ maxWidth: "60px" }}
                              />
                            </Grid>

                            {/* <Grid item xs={12}>
                              <div className="input__group">
                                <div className="input__group--radio">
                                  <input
                                    checked={val.sign === ">"}
                                    value=">"
                                    onChange={(e) =>
                                      handleChange(e, "sign", index)
                                    }
                                    id={`greaterThan${index}`}
                                    type="radio"
                                    name={`sign${index}`}
                                  />
                                  <label for={`greaterThan${index}`}>
                                    Greater Than
                                  </label>
                                </div>
                                <div className="input__group--radio">
                                  <input
                                    checked={val.sign === "<"}
                                    value="<"
                                    onChange={(e) =>
                                      handleChange(e, "sign", index)
                                    }
                                    id={`lessThan${index}`}
                                    type="radio"
                                    name={`sign${index}`}
                                  />
                                  <label for={`lessThan${index}`}>
                                    Less Than
                                  </label>
                                </div>
                              </div>
                            </Grid> */}
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                value={val.standardDeviation}
                                onChange={(e) =>
                                  handleChange(e, "standardDeviation", index)
                                }
                                label="Standard Deviation"
                                variant="outlined"
                                error={isError && !val.standardDeviation}
                                helperText={
                                  isError &&
                                  !val.standardDeviation &&
                                  "Standard Deviation is required"
                                }
                              />
                              <TextField
                                value={val.digitsStandardDeviation}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    "digitsStandardDeviation",
                                    index
                                  )
                                }
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                label="Digit"
                                variant="outlined"
                                style={{ maxWidth: "60px" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                value={val.expandedUncertainity}
                                onChange={(e) =>
                                  handleChange(e, "expandedUncertainity", index)
                                }
                                label="Expanded Uncertainity"
                                variant="outlined"
                                error={isError && !grade}
                                helperText={
                                  isError && !grade && "Grade is required"
                                }
                              />
                              <TextField
                                value={val.digitsExpandedUncertainity}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    "digitsExpandedUncertainity",
                                    index
                                  )
                                }
                                id="outlined-basic"
                                fullWidth
                                required
                                size="small"
                                label="Digit"
                                variant="outlined"
                                style={{ maxWidth: "60px" }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <Grid item xs={12} className="grid--action">
                          <div className="input__group">
                            <div className="input__group--radio">
                              <input
                                checked={val.sign === ">"}
                                value=">"
                                onChange={(e) => handleChange(e, "sign", index)}
                                id={`greaterThan${index}`}
                                type="radio"
                                name={`sign${index}`}
                              />
                              <label for={`greaterThan${index}`}>
                                Greater Than
                              </label>
                            </div>
                            <div className="input__group--radio">
                              <input
                                checked={val.sign === "<"}
                                value="<"
                                onChange={(e) => handleChange(e, "sign", index)}
                                id={`lessThan${index}`}
                                type="radio"
                                name={`sign${index}`}
                              />
                              <label for={`lessThan${index}`}>Less Than</label>
                            </div>
                          </div>

                          <div className="table__actions--wrapper">
                            <div className="table__actions remove--entry">
                              <Tooltip title="Remove Entry" placement="right">
                                <button
                                  onClick={() => {
                                    removefields(index);
                                  }}
                                  className="btn btn--primary"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                  </svg>
                                </button>
                              </Tooltip>
                            </div>
                            {index === fields.length - 1 ? (
                              <div className="table__actions add--entry">
                                <Tooltip title="Add Entry" placement="right">
                                  <button
                                    onClick={() => {
                                      addfields(index);
                                    }}
                                    className="btn btn--primary"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                  </button>
                                </Tooltip>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                      </>
                    );
                  })
                : null}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={Class}
                  onChange={(e) => setData({ ...data, Class: e.target.value })}
                  label="Class"
                  variant="outlined"
                  error={isError && !Class}
                  helperText={isError && !Class && "Class is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={grade}
                  onChange={(e) => {
                    setData({ ...data, grade: e.target.value });
                  }}
                  label="Grade"
                  variant="outlined"
                  error={isError && !grade}
                  helperText={isError && !grade && "Grade is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={uns}
                  onChange={(e) => setData({ ...data, uns: e.target.value })}
                  label="Uns"
                  variant="outlined"
                  error={isError && !uns}
                  helperText={isError && !uns && "Uns is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={pin}
                  onChange={(e) => {
                    setData({
                      ...data,
                      pin: e.target.value,
                    });
                  }}
                  label="PIN"
                  variant="outlined"
                  error={isError && !pin}
                  helperText={isError && !pin && "PIN is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={rmrm}
                  onChange={(e) => setData({ ...data, rmrm: e.target.value })}
                  label="Rmrm"
                  variant="outlined"
                  error={isError && !rmrm}
                  helperText={isError && !rmrm && "Rmrm is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={series}
                  onChange={(e) => setData({ ...data, series: e.target.value })}
                  label="Series"
                  variant="outlined"
                  error={isError && !series}
                  helperText={isError && !series && "Series is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={length}
                  onChange={(e) => setData({ ...data, length: e.target.value })}
                  label="Length"
                  variant="outlined"
                  error={isError && !length}
                  helperText={isError && !length && "Length is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={cost}
                  onChange={(e) => setData({ ...data, cost: e.target.value })}
                  label="Cost"
                  variant="outlined"
                  error={isError && !cost}
                  helperText={isError && !cost && "Cost is required"}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={freight}
                  onChange={(e) =>
                    setData({ ...data, freight: e.target.value })
                  }
                  label="Freight"
                  variant="outlined"
                  error={isError && !freight}
                  helperText={isError && !freight && "Freight is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={totalCost}
                  onChange={(e) =>
                    setData({ ...data, totalCost: e.target.value })
                  }
                  label="TotalCost"
                  variant="outlined"
                  error={isError && !totalCost}
                  helperText={isError && !totalCost && "Total Cost is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={poDate}
                  onChange={(e) => setData({ ...data, poDate: e.target.value })}
                  label="PoDate"
                  variant="outlined"
                  error={isError && !poDate}
                  helperText={isError && !poDate && "Po Date is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={po}
                  onChange={(e) => setData({ ...data, po: e.target.value })}
                  label="Po"
                  variant="outlined"
                  error={isError && !po}
                  helperText={isError && !po && "Po is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={heat}
                  onChange={(e) => setData({ ...data, heat: e.target.value })}
                  label="Heat"
                  variant="outlined"
                  error={isError && !heat}
                  helperText={isError && !heat && "Heat is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={poSource}
                  onChange={(e) =>
                    setData({ ...data, poSource: e.target.value })
                  }
                  label="PoSource"
                  variant="outlined"
                  error={isError && !poSource}
                  helperText={isError && !poSource && "Po Source is required"}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={mill}
                  onChange={(e) => setData({ ...data, mill: e.target.value })}
                  label="Mill"
                  variant="outlined"
                  error={isError && !mill}
                  helperText={isError && !mill && "Mill is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={received}
                  onChange={(e) =>
                    setData({ ...data, received: e.target.value })
                  }
                  label="Received"
                  variant="outlined"
                  error={isError && !received}
                  helperText={isError && !received && "Received is required"}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={final}
                  onChange={(e) => setData({ ...data, final: e.target.value })}
                  label="Final"
                  variant="outlined"
                  error={isError && !final}
                  helperText={isError && !final && "Final is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={replaced}
                  onChange={(e) =>
                    setData({ ...data, replaced: e.target.value })
                  }
                  label="Replaced"
                  variant="outlined"
                  error={isError && !replaced}
                  helperText={isError && !replaced && "Replaced is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={symbol}
                  onChange={(e) => setData({ ...data, symbol: e.target.value })}
                  label="Symbol"
                  variant="outlined"
                  error={isError && !symbol}
                  helperText={isError && !symbol && "Symbol is required"}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarperinchminusthefreight}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarperinchminusthefreight: e.target.value,
                    })
                  }
                  label="DollarPerInchMinusTheFreight"
                  variant="outlined"
                  error={isError && !dollarperinchminusthefreight}
                  helperText={
                    isError &&
                    !dollarperinchminusthefreight &&
                    "DollarPerInchMinusTheFreight is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarperinch}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarperinch: e.target.value,
                    })
                  }
                  label="DollarPerInch"
                  variant="outlined"
                  error={isError && !dollarperinch}
                  helperText={
                    isError && !dollarperinch && "DollarPerInch is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarpermilimeterminusfreight}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarpermilimeterminusfreight: e.target.value,
                    })
                  }
                  label="DollarPerMilimeterMinusFreight"
                  variant="outlined"
                  error={isError && !dollarpermilimeterminusfreight}
                  helperText={
                    isError &&
                    !dollarpermilimeterminusfreight &&
                    "DollarPerMilimeterMinusFreight is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarpermilimeter}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarpermilimeter: e.target.value,
                    })
                  }
                  label="DollarPerMilimeter"
                  variant="outlined"
                  error={isError && !dollarpermilimeter}
                  helperText={
                    isError &&
                    !dollarpermilimeter &&
                    "DollarPerMilimeter is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarper35milimeterpiece}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarper35milimeterpiece: e.target.value,
                    })
                  }
                  label="DollarPer35MilimeterPiece"
                  variant="outlined"
                  error={isError && !dollarper35milimeterpiece}
                  helperText={
                    isError &&
                    !dollarper35milimeterpiece &&
                    "DollarPer35MilimeterPiece is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarper19milimeterpiece}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarper19milimeterpiece: e.target.value,
                    })
                  }
                  label="DollarPer19MilimeterPiece"
                  variant="outlined"
                  error={isError && !dollarper19milimeterpiece}
                  helperText={
                    isError &&
                    !dollarper19milimeterpiece &&
                    "DollarPer19MilimeterPiece is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarper3milimeterpiece}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarper3milimeterpiece: e.target.value,
                    })
                  }
                  label="DollarPer3MilimeterPiece"
                  variant="outlined"
                  error={isError && !dollarper3milimeterpiece}
                  helperText={
                    isError &&
                    !dollarper3milimeterpiece &&
                    "DollarPer3MilimeterPiece is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarper12milimeterpiece}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarper12milimeterpiece: e.target.value,
                    })
                  }
                  label="DollarPer12MilimeterPiece"
                  variant="outlined"
                  error={isError && !dollarper12milimeterpiece}
                  helperText={
                    isError &&
                    !dollarper12milimeterpiece &&
                    "DollarPer12MilimeterPiece is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarper8milimeterpiece}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarper8milimeterpiece: e.target.value,
                    })
                  }
                  label="DollarPer8MilimeterPiece"
                  variant="outlined"
                  error={isError && !dollarper8milimeterpiece}
                  helperText={
                    isError &&
                    !dollarper8milimeterpiece &&
                    "DollarPer8MilimeterPiece is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarpergram}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarpergram: e.target.value,
                    })
                  }
                  label="DollarPerGram"
                  variant="outlined"
                  error={isError && !dollarpergram}
                  helperText={
                    isError && !dollarpergram && "DollarPerGram is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={inventoryamountininches}
                  onChange={(e) =>
                    setData({
                      ...data,
                      inventoryamountininches: e.target.value,
                    })
                  }
                  label="InventoryAmountInInches"
                  variant="outlined"
                  error={isError && !inventoryamountininches}
                  helperText={
                    isError &&
                    !inventoryamountininches &&
                    "InventoryAmountInInches is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={inventoryinmilimeter}
                  onChange={(e) =>
                    setData({
                      ...data,
                      inventoryinmilimeter: e.target.value,
                    })
                  }
                  label="InventoryInMilimeter"
                  variant="outlined"
                  error={isError && !inventoryinmilimeter}
                  helperText={
                    isError &&
                    !inventoryinmilimeter &&
                    "InventoryInMilimeter is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={dollarvalueofinventory}
                  onChange={(e) =>
                    setData({
                      ...data,
                      dollarvalueofinventory: e.target.value,
                    })
                  }
                  label="DollarValueOfInventory"
                  variant="outlined"
                  error={isError && !dollarvalueofinventory}
                  helperText={
                    isError &&
                    !dollarvalueofinventory &&
                    "DollarValueOfInventory is required"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={date}
                  onChange={(e) => setData({ ...data, date: e.target.value })}
                  label="Date"
                  variant="outlined"
                  error={isError && !date}
                  helperText={isError && !date && "Date is required"}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <Button
            autoFocus
            onClick={handleSubmit}
            className={"btnClass"}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 2 }}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  fieldData,
  isLoading,
  setLoading,
  pageData,
  search,
  setSearch,
  setOpen,
  modalInBackground,
  setModalInBackground,
  sortingOrder,
  fetchData,
}) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Inventory Listing
      </Typography>
      {/* <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
        className="input__search"
      >
        <InputBase
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Certificates"
          inputProps={{ "aria-label": "search certificates" }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper> */}
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        fieldData={fieldData}
        isLoading={isLoading}
        setLoading={setLoading}
        pageData={pageData}
        setOpen={setOpen}
        search={search}
        setSearch={setSearch}
        fetchData={fetchData}
        sortingOrder={sortingOrder}
        modalInBackground={modalInBackground}
        setModalInBackground={setModalInBackground}
      />
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  isLoading,
  setLoading,
  fetchData,
  pageNumber,
  setPageNumber,
  totalCount,
  search,
  setSearch,
  columns,
  sortingOrder,
}) {
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
  };
  const [modalInBackground, setModalInBackground] = useState(false);

  const [fieldData, setfieldData] = useState([]);
  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      primaryClass: "",
      analyte: [],
      pin: "",
      grade: "",
      uns: "",
      Class: "",
      rmrm: "",
      series: "",
      length: "",
      cost: "",
      freight: "",
      totalCost: "",
      poDate: "",
      po: "",
      heat: "",
      poSource: "",
      mill: "",
      received: "",
      final: "",
      replaced: "",
      symbol: "",

      dollarperinchminusthefreight: "",
      dollarperinch: "",
      dollarpermilimeterminusfreight: "",
      dollarpermilimeter: "",
      dollarper35milimeterpiece: "",
      dollarper19milimeterpiece: "",
      dollarper3milimeterpiece: "",
      dollarper12milimeterpiece: "",
      dollarper8milimeterpiece: "",
      dollarpergram: "",
      inventoryamountininches: "",
      inventoryinmilimeter: "",
      dollarvalueofinventory: "",
      date: "",
    }),
    handleClickOpen = (data) => {
      if (data && data.analyte) setfieldData(data.analyte);
      else
        setfieldData([
          {
            sign: "",
            valueAssignment: "",
            standardDeviation: "",
            expandedUncertainity: "",
          },
        ]);
      setPageData(data);
      setModalInBackground(false);
      setOpen(true);
    },
    handleDelete = async (data) => {
      const {
        id,
        primaryClass,
        analyte,
        pin,
        grade,
        uns,
        Class,
        rmrm,
        series,
        length,
        cost,
        freight,
        totalCost,
        poDate,
        po,
        heat,
        poSource,
        mill,
        received,
        final,
        replaced,
        symbol,

        dollarperinchminusthefreight,
        dollarperinch,
        dollarpermilimeterminusfreight,
        dollarpermilimeter,
        dollarper35milimeterpiece,
        dollarper19milimeterpiece,
        dollarper3milimeterpiece,
        dollarper12milimeterpiece,
        dollarper8milimeterpiece,
        dollarpergram,
        inventoryamountininches,
        inventoryinmilimeter,
        dollarvalueofinventory,
        date,
      } = data;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            {
              id,
              primaryClass,
              analyte,
              pin,
              grade,
              uns,
              Class,
              rmrm,
              series,
              length,
              cost,
              freight,
              totalCost,
              poDate,
              po,
              heat,
              poSource,
              mill,
              received,
              final,
              replaced,
              symbol,

              dollarperinchminusthefreight,
              dollarperinch,
              dollarpermilimeterminusfreight,
              dollarpermilimeter,
              dollarper35milimeterpiece,
              dollarper19milimeterpiece,
              dollarper3milimeterpiece,
              dollarper12milimeterpiece,
              dollarper8milimeterpiece,
              dollarpergram,
              inventoryamountininches,
              inventoryinmilimeter,
              dollarvalueofinventory,
              date,
              active: 0,
            },
            "/analytes/add"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    handleClose = () => {
      setPageData({
        id: 0,
        analyte: [],
        pin: "",
        grade: "",
      });
      setModalInBackground(false);
      setOpen(false);
      fetchData();
    };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  const showTooltip = (string) => {
    return string;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            link={link}
            fetchData={fetchData}
            search={search}
            setSearch={setSearch}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            fieldData={fieldData}
            isLoading={isLoading}
            setLoading={setLoading}
            pageData={pageData}
            setOpen={setOpen}
            modalInBackground={modalInBackground}
            sortingOrder={sortingOrder}
            setModalInBackground={setModalInBackground}
          />
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={""}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Action</TableCell>
                <TableCell align="center">
                  Primary
                  <br /> Class
                </TableCell>
                {/* <TableCell>Analyte</TableCell> */}
                <TableCell align="center">Grade</TableCell>
                <TableCell align="center">RMRM or RM Number</TableCell>
                <TableCell align="center">Series</TableCell>

                <TableCell align="center">PIN</TableCell>
                {columns.map((row) => (
                  <TableCell align="center">{row}</TableCell>
                ))}
                <TableCell align="center">Material Sub Class</TableCell>
                <TableCell align="center">UNS Number</TableCell>

                <TableCell align="center">Length Ordered</TableCell>
                <TableCell align="center">Material Cost</TableCell>
                <TableCell align="center">Freight Cost</TableCell>
                <TableCell align="center" className="breakSpace">
                  <Tooltip title="Total Cost">
                    <div>Material Total Cost</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="center" className="breakSpace">
                  PO Date
                </TableCell>
                <TableCell
                  align="center"
                  className="breakSpace"
                  style={{ minWidth: "100px" }}
                >
                  PO Number
                </TableCell>
                <TableCell align="center">Heat Number</TableCell>
                <TableCell align="center">Material Source</TableCell>
                <TableCell align="center">Material Mill</TableCell>
                <TableCell align="center">Date Received</TableCell>
                <TableCell align="center" style={{ minWidth: "100px" }}>
                  Final Inches
                </TableCell>
                <TableCell align="center">Item Replaced</TableCell>

                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per Inch Less Freight")}
                </TableCell>
                <TableCell align="center">
                  {showTooltip("Total Cost per Inch")}
                </TableCell>
                <TableCell align="center">
                  {showTooltip("Cost per mm Less Freight")}
                </TableCell>
                <TableCell align="center">
                  {showTooltip("Total Cost per mm")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per 35 mm Piece")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per 19 mm Piece")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per 3 mm Piece")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per 12 mm Piece")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Cost per 8 mm Piece")}
                </TableCell>
                <TableCell align="center">
                  {showTooltip("Cost per Gram")}
                </TableCell>
                <TableCell align="center" className="minCellWidth">
                  Inventory in Inches
                </TableCell>

                <TableCell align="center" className="minCellWidth">
                  {showTooltip("Inventory in mm")}
                </TableCell>
                <TableCell align="center">
                  {showTooltip("Inventory Cost")}
                </TableCell>
                <TableCell align="center">Inventory Date</TableCell>

                <TableCell align="center" className="minCellWidth">
                  Item Active
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0
                ? rows.map((row, index) => (
                    <TableRow
                      key={Math.random(6).toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        <div className="buttonWrap">
                          {" "}
                          <Fab
                            color="primary"
                            onClick={() => handleClickOpen(row)}
                            aria-label="add"
                            size="small"
                            sx={{ mr: 1 }}
                            style={{
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px",
                              zIndex: "99",
                            }}
                          >
                            <EditIcon />
                          </Fab>
                          <Fab
                            color="error"
                            aria-label="delete"
                            size="small"
                            onClick={() => handleDelete(row)}
                            style={{
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px",
                              zIndex: "99",
                            }}
                          >
                            <DeleteIcon />
                          </Fab>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Tooltip
                          title={
                            row.symbol && row.symbol !== ""
                              ? row.primaryClass + "-" + row.symbol
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.primaryClass}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title={row.grade.length > 10 ? row.grade : ""}>
                          <div className="text--wrapping">
                            {row.grade.length > 10
                              ? row.grade.substring(0, 10) + ".."
                              : row.grade}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.rmrm.length > 6 ? row.rmrm : ""}>
                          <div className="text--wrapping">
                            {row.rmrm.length > 6
                              ? row.rmrm.substring(0, 6) + ".."
                              : row.rmrm}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip
                          title={row.series.length > 6 ? row.series : ""}
                        >
                          <div className="text--wrapping">
                            {row.series.length > 6
                              ? row.series.substring(0, 6) + ".."
                              : row.series}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">{row.pin}</TableCell>
                      {columns.map((col) => (
                        <TableCell align="center">
                          <Tooltip
                            title={
                              row.excel?.[col]?.toString().length > 6
                                ? row.excel?.[col]?.toString()
                                : ""
                            }
                          >
                            <div className="text--wrapping">
                              {row.excel?.[col]?.toString().length > 6
                                ? row.excel?.[col]?.toString().substring(0, 6) +
                                  ".."
                                : row.excel?.[col]?.toString()}
                            </div>
                          </Tooltip>
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <Tooltip title={row.Class.length > 6 ? row.Class : ""}>
                          <div className="text--wrapping">
                            {row.Class.length > 6
                              ? row.Class.substring(0, 6) + ".."
                              : row.Class}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title={row.uns.length > 6 ? row.uns : ""}>
                          <div className="text--wrapping">
                            {row.uns.length > 6
                              ? row.uns.substring(0, 6) + ".."
                              : row.uns}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={row.length.length > 6 ? row.length : ""}
                        >
                          <div className="text--wrapping">
                            {row.length.length > 6
                              ? row.length.substring(0, 6) + ".."
                              : row.length}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.cost.length > 6 ? row.cost : ""}>
                          <div className="text--wrapping">
                            {row.cost.length > 6
                              ? row.cost.substring(0, 6) + ".."
                              : row.cost}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={row.freight.length > 6 ? row.freight : ""}
                        >
                          <div className="text--wrapping">
                            {row.freight.length > 6
                              ? row.freight.substring(0, 6) + ".."
                              : row.freight}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={row.totalCost.length > 6 ? row.totalCost : ""}
                        >
                          <div className="text--wrapping">
                            {row.totalCost.length > 6
                              ? row.totalCost.substring(0, 6) + ".."
                              : row.totalCost}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" className="nowrap">
                        {" "}
                        <Tooltip
                          title={
                            row.poDate.length > 6 ? formatDate(row.poDate) : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.poDate.length > 6
                              ? formatDate(row.poDate).substring(0, 6) + ".."
                              : formatDate(row.poDate)}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip title={row.po.length > 6 ? row.po : ""}>
                          <div className="text--wrapping">
                            {row.po.length > 6
                              ? row.po.substring(0, 6) + ".."
                              : row.po}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip title={row.heat.length > 6 ? row.heat : ""}>
                          <div className="text--wrapping">
                            {row.heat.length > 6
                              ? row.heat.substring(0, 6) + ".."
                              : row.heat}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={row.poSource.length > 8 ? row.poSource : ""}
                        >
                          <div className="text--wrapping">
                            {row.poSource.length > 8
                              ? row.poSource.substring(0, 8) + ".."
                              : row.poSource}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip title={row.mill.length > 6 ? row.mill : ""}>
                          <div className="text--wrapping">
                            {row.mill.length > 6
                              ? row.mill.substring(0, 6) + ".."
                              : row.mill}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" className="nowrap">
                        <Tooltip
                          title={
                            row.received.length > 6
                              ? formatDate(row.received)
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.received.length > 6
                              ? formatDate(row.received).substring(0, 6) + ".."
                              : formatDate(row.received)}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.final.length > 6 ? row.final : ""}>
                          <div className="text--wrapping">
                            {row.final.length > 6
                              ? row.final.substring(0, 6) + ".."
                              : row.final}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={row.replaced.length > 6 ? row.replaced : ""}
                        >
                          <div className="text--wrapping">
                            {row.replaced.length > 6
                              ? row.replaced.substring(0, 6) + ".."
                              : row.replaced}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarperinchminusthefreight.length > 6
                              ? row.dollarperinchminusthefreight
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarperinchminusthefreight.length > 6
                              ? row.dollarperinchminusthefreight.substring(
                                  0,
                                  6
                                ) + ".."
                              : row.dollarperinchminusthefreight}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarperinch.length > 6
                              ? row.dollarperinch
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarperinch.length > 6
                              ? row.dollarperinch.substring(0, 6) + ".."
                              : row.dollarperinch}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarpermilimeterminusfreight.length > 6
                              ? row.dollarpermilimeterminusfreight
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarpermilimeterminusfreight.length > 6
                              ? row.dollarpermilimeterminusfreight.substring(
                                  0,
                                  6
                                ) + ".."
                              : row.dollarpermilimeterminusfreight}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarpermilimeter.length > 6
                              ? row.dollarpermilimeter
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarpermilimeter.length > 6
                              ? row.dollarpermilimeter.substring(0, 6) + ".."
                              : row.dollarpermilimeter}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarper35milimeterpiece.length > 6
                              ? row.dollarper35milimeterpiece
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarper35milimeterpiece.length > 6
                              ? row.dollarper35milimeterpiece.substring(0, 6) +
                                ".."
                              : row.dollarper35milimeterpiece}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarper19milimeterpiece.length > 6
                              ? row.dollarper19milimeterpiece
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarper19milimeterpiece.length > 6
                              ? row.dollarper19milimeterpiece.substring(0, 6) +
                                ".."
                              : row.dollarper19milimeterpiece}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarper3milimeterpiece.length > 6
                              ? row.dollarper3milimeterpiece
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarper3milimeterpiece.length > 6
                              ? row.dollarper3milimeterpiece.substring(0, 6) +
                                ".."
                              : row.dollarper3milimeterpiece}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarper12milimeterpiece.length > 6
                              ? row.dollarper12milimeterpiece
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarper12milimeterpiece.length > 6
                              ? row.dollarper12milimeterpiece.substring(0, 6) +
                                ".."
                              : row.dollarper12milimeterpiece}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarper8milimeterpiece.length > 6
                              ? row.dollarper8milimeterpiece
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarper8milimeterpiece.length > 6
                              ? row.dollarper8milimeterpiece.substring(0, 6) +
                                ".."
                              : row.dollarper8milimeterpiece}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarpergram.length > 6
                              ? row.dollarpergram
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarpergram.length > 6
                              ? row.dollarpergram.substring(0, 6) + ".."
                              : row.dollarpergram}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.inventoryamountininches.length > 6
                              ? row.inventoryamountininches
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.inventoryamountininches.length > 6
                              ? row.inventoryamountininches.substring(0, 6) +
                                ".."
                              : row.inventoryamountininches}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.inventoryinmilimeter.length > 6
                              ? row.inventoryinmilimeter
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.inventoryinmilimeter.length > 6
                              ? row.inventoryinmilimeter.substring(0, 6) + ".."
                              : row.inventoryinmilimeter}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            row.dollarvalueofinventory.length > 6
                              ? row.dollarvalueofinventory
                              : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.dollarvalueofinventory.length > 6
                              ? row.dollarvalueofinventory.substring(0, 6) +
                                ".."
                              : row.dollarvalueofinventory}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" className="nowrap">
                        <Tooltip
                          title={
                            row.date.length > 6 ? formatDate(row.date) : ""
                          }
                        >
                          <div className="text--wrapping">
                            {row.date.length > 6
                              ? formatDate(row.date).substring(0, 6) + ".."
                              : formatDate(row.date)}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        {row.active ? "Active" : "Inactive"}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalCount / 15)}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
        />
      </Paper>
    </Box>
  );
}
